<template>
  <div class="conocenos-c">
    <Navigator />
    <div class="con-c">
      <div class="header-c-c"></div>
      <div v-html="about.aboutDescription" class="texto__about">

      </div>
      <!-- <p class="con-p">
        Amamos la comida, lo que sucede y existe alrededor de ella, buscamos
        atender el sentido de la intuición, esa corazonada que nos dice que
        estamos ante algo nuevo, algo delicioso, algo que nos hará felices y que
        queremos compartir contigo.
      </p>
      <p class="con-p">¡Hablemos de comida!</p> -->
      <div v-if="aboutuser1[0].id" class="people-con mtb">
        <div v-if="aboutuser1[0].image !== '' && aboutuser1[0].image">
            <img  :src="srcImg(aboutuser1[0].image)" alt="people" class="people-img" />
          </div>
         <div class="no-img" v-else></div>
        <p class="people-name">{{ aboutuser1[0].name }}</p>
        <p v-if="aboutuser1[0].role" class="people-t">{{ aboutuser1[0].role }}</p>
        <a v-if="aboutuser1[0].email" href="mailto:micamen@revistafoodie.com" class="people-mail">{{ aboutuser1[0].email }}</a>
      </div>

      <div class="collabs-c">
        <div v-for="(item, index) in aboutuser" :key="index" class="people-con">
          <div v-if="item.image !== '' && item.image">
            <img  :src="srcImg(item.image)" alt="people" class="people-img" />
          </div>
          <div class="no-img" v-else></div>
          <p class="people-name">{{ item.name }}</p>
          <p v-if="item.role" class="people-t">{{ item.role }}</p>
          <a v-if="item.email" href="mailto:renee@revistafoodie.com" class="people-mail">{{ item.email }}</a>
        </div>
       
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Navigator from "../components/items-page/Navigator";

let urlpath = require("../global/url");
import Footer from "../components/items-page/Footer";

export default {
  components: {
    Navigator,
    Footer,
  },
  data(){
    return {
      urlpath
    }
  },
  async created() {
    await this.getAllInfoAbt("about");
    await this.getAllInfoAsr("about_user");
  },

  computed: {
    about() {
      const aboutAll = this.$store.getters["about/data"][0];
      if(!aboutAll) return '';
      return aboutAll;
    },
    aboutuser() {
      const aboutAll = this.$store.getters["aboutuser/data"];
      if(!Array.isArray(aboutAll)) return [];

      const filt = aboutAll.filter(item => item.status === 1 && item.orden !== 1)

      const sortedArray = filt.sort((a, b) => {
        if (a.orden === 0) return 1;
        if (b.orden === 0) return -1;
        return a.orden - b.orden;
      });

      return sortedArray;
    },

    aboutuser1() {
      const aboutAll = this.$store.getters["aboutuser/data"];
      if(!Array.isArray(aboutAll)) return [];

      const filt = aboutAll.filter(item => item.status === 1 && item.orden === 1)

      return filt;
    },
  },

  methods: {
    ...mapActions('about', ['setAddedAbt', 'getAllInfoAbt']),
    ...mapActions("aboutuser", ["getAllInfoAsr"]),

    srcImg:   function (img){
        if(!img || img =='') return ''
        let src = `${urlpath.url()}/about_user-img/${img}`;
          return src
        },

  },
};
</script>
<style>
.header-c-c {
  width: 100%;
  height: 38.217vw;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/header.png");
  margin-bottom: 24.155vw;
}

.con-c {
  padding-bottom: 24.155vw;
}

.con-c p {
  font-size: 5vw;
  line-height: 5vw;
  font-weight: 600;
  text-align: center;
  margin: auto 1vw 4.831vw;
}

.people-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6.831vw;
}

.people-con.mtb {
  padding: 24vw 0 54vw;
}

.people-con .people-img, .no-img{
  width: 78.502vw;
  margin-bottom: 12.039vw;
}
.no-img{
  height: 78.502vw;
}
.people-con .people-name {
  padding: 9.662vw 0;
  width: 86.957vw;
  background: black;
  color: white;
  margin: auto;
}

.people-con .people-t {
  margin: 4.831vw auto 0;
}

.people-con .people-mail {
  font-size: 5vw;
  line-height: 5vw;
  color: black;
  text-align: center;
}

.collabs-c .people-con .people-name {
  border: 1vw solid black;
  background: white;
  color: black;
  width: 71.618vw;
  padding: 4.831vw 0;
}

@media (min-width: 768px) {
  .header-c-c {
    width: 100%;
    height: 12.74vw;
    margin-bottom: 5.208vw;
  }

  .con-c {
    padding-bottom: 5.208vw;
  }

  .con-c p {
    font-size: 1.4vw;
    line-height: 1.6vw;
    margin: auto auto 1.831vw;
  }

  .con-c .con-p {
    width: 60vw;
  }

  .texto__about {
    width: 60vw;
    margin: 0 auto;
  }

  .collabs-c {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .people-con {
    margin: 0 2vw 6.831vw;
  }

  .people-con .people-img, .no-img {
    width: 27.344vw;
    margin-bottom: 3.039vw;
  }
  .no-img{
  height: 27.344vw;
}
  .people-con.mtb {
    padding: 6vw 0 12vw;
    margin: 0;
  }

  .people-con .people-name {
    padding: 2.083vw 0;
    width: 22.396vw;
  }

  .people-con .people-t {
    margin: 2.831vw auto 0.6vw;
  }

  .people-con .people-mail {
    font-size: 1vw;
    line-height: 1.3vw;
  }

  .collabs-c .people-con .people-name {
    border: 0.3vw solid black;
    background: white;
    color: black;
    width: 17.833vw;
    padding: 2.083vw 0;
  }

  .collabs-c .people-con .people-img, .no-img {
    width: 16.927vw;
    margin-bottom: 3.039vw;
  }
  .no-img{
  height: 16.927vw;
}
}
</style>